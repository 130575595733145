<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Data Desain'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12" class="mb-2" v-if="akses">
              <b-button variant="outline-dark" @click="$router.push('/datadesain/add')">
                <span class="svg-icon svg-icon-sm svg-icon-dark">
                  <i class="flaticon2-plus"></i>
                </span>
                Upload Dokumen
              </b-button>
              <b-button
                class="ml-2"
                variant="outline-dark"
                @click="$router.push('/datadesain/inputdata')"
              >
                <span class="svg-icon svg-icon-sm svg-icon-dark">
                  <i class="flaticon2-plus"></i>
                </span>
                Input Data
              </b-button>
            </b-col>
            <b-col md="12">
              <b-table
                head-variant="dark"
                bordered
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                stacked="lg"
                show-empty
                responsive
                hover
                :no-local-sorting="true"
                @sort-changed="sortTable"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @row-clicked="info"
                @filtered="onFiltered"
                :busy="isBusy"
              >
                <template #cell(nama_equipment)="data">
                  {{ `${data.item.nama_equipment} (${data.item.nomor_equipment})` }}
                </template>

                <template #thead-top="data">
                  <b-tr>
                    <b-th colspan="1"></b-th>
                    <b-th>
                      <b-form-input
                        id="filter-1"
                        size="sm"
                        @input="loadData"
                        v-model="search.tanggal"
                        type="date"
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-2"
                        size="sm"
                        @input="loadData"
                        v-model="search.nomor_dokumen"
                        type="search"
                        placeholder="Cari Nomor Dokumen..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-3"
                        size="sm"
                        @input="loadData"
                        v-model="search.jenis_dokumen"
                        type="search"
                        placeholder="Cari Jenis Dokumen..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-5"
                        size="sm"
                        @input="loadData"
                        v-model="search.versi_dokumen"
                        type="search"
                        placeholder="Cari Versi Dokumen..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-6"
                        size="sm"
                        @input="loadData"
                        v-model="search.equipment"
                        type="search"
                        placeholder="Cari Equipment..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-4"
                        size="sm"
                        @input="loadData"
                        v-model="search.nama_plant"
                        type="search"
                        placeholder="Cari Plant/Unit..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-7"
                        size="sm"
                        @input="loadData"
                        v-model="search.nama_pabrik"
                        type="search"
                        placeholder="Cari Pabrik..."
                      ></b-form-input>
                    </b-th>
                    <b-th colspan="1"></b-th>
                  </b-tr>
                </template>

                <template #cell(no)="data">
                  {{ (currentPage - 1) * 10 + data.index + 1 }}
                </template>

                <template #cell(actions)="data">
                  <b-dropdown text=". . ." variant="danger" size="sm" no-caret>
                    <b-dropdown-item
                      :href="urlStorage + '/file/data_desain/' + data.item.file"
                      target="_blank"
                      v-if="data.item.file"
                    >
                      <a>
                        <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                          <span class="svg-icon svg-icon-sm svg-icon-info">
                            <i class="flaticon-download text-info"></i>
                          </span>
                        </div>
                        File
                      </a>
                    </b-dropdown-item>
                    <b-dropdown-item-button v-else>
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-dark">
                          <i class="flaticon-circle text-dark"></i>
                        </span>
                      </div>
                      No File
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="info(data.item)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-success">
                          <i class="flaticon-eye text-success"></i>
                        </span>
                      </div>
                      View
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      v-if="akses"
                      @click="$router.push('/datadesain/edit/' + data.item.id_data_desain)"
                    >
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-primary">
                          <i class="flaticon2-pen text-primary"></i>
                        </span>
                      </div>
                      Edit
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="toDelete(data.item)" v-if="akses">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-danger">
                          <i class="flaticon2-trash text-danger"></i>
                        </span>
                      </div>
                      Delete
                    </b-dropdown-item-button>
                  </b-dropdown>
                </template>

                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
              <b-modal
                id="info-modal"
                size="xl"
                :title="`${modalInfo.nomor_equipment} - ${modalInfo.jenis_dokumen}`"
                header-class="text-center"
                ok-only
                ok-title="Tutup"
                ok-variant="secondary"
                v-model="infoModal"
                @hide="resetInfoModal"
              >
                <b-row>
                  <b-col>
                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"><b>Pabrik :</b></b-col>
                      <b-col v-if="modalInfo.nama_pabrik">{{ modalInfo.nama_pabrik }}</b-col>
                      <b-col v-else>-</b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"><b>Plant :</b></b-col>
                      <b-col v-if="modalInfo.nama_plant">{{ modalInfo.nama_plant }}</b-col>
                      <b-col v-else>-</b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"><b>Equipment :</b></b-col>
                      <b-col v-if="modalInfo.nama_equipment">{{
                        modalInfo.nama_equipment + ' (' + modalInfo.nomor_equipment + ')'
                      }}</b-col>
                      <b-col v-else>-</b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"><b>Tanggal :</b></b-col>
                      <b-col v-if="modalInfo.tanggal">{{ moment(modalInfo.tanggal) }}</b-col>
                      <b-col v-else>-</b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"><b>Nomor Dokumen :</b></b-col>
                      <b-col v-if="modalInfo.nomor_dokumen">{{ modalInfo.nomor_dokumen }}</b-col>
                      <b-col v-else>-</b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"><b>Jenis Dokumen :</b></b-col>
                      <b-col v-if="modalInfo.jenis_dokumen">{{ modalInfo.jenis_dokumen }}</b-col>
                      <b-col v-else>-</b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"><b>Versi Dokumen :</b></b-col>
                      <b-col v-if="modalInfo.versi_dokumen">{{ modalInfo.versi_dokumen }}</b-col>
                      <b-col v-else>-</b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mx-1 my-3">
                  <div style="height: 300px" v-if="modalInfo.file.includes(`.pdf`)">
                    <pdf
                      :src="urlStorage + '/file/data_desain/' + modalInfo.file"
                      style="width: 100%"
                    ></pdf>
                  </div>
                  <b-col v-else md="6" offset-md="3">
                    <b-img
                      :src="urlStorage + '/file/data_desain/' + modalInfo.file"
                      fluid
                      style="width: 100%"
                    ></b-img>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-center">
                    <a
                      :href="urlStorage + '/file/data_desain/' + modalInfo.file"
                      target="_blank"
                      v-if="modalInfo.file"
                    >
                      <div class="btn btn-icon btn-clean btn-xl btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-xl svg-icon-info">
                          <i class="flaticon-download text-info"></i>
                        </span>
                      </div>
                    </a>
                    <a
                      v-if="akses"
                      @click="$router.push('/datadesain/edit/' + modalInfo.id_data_desain)"
                    >
                      <div class="btn btn-icon btn-clean btn-xl btn-dropdown mr-1 mx-3">
                        <span class="svg-icon svg-icon-xl svg-icon-primary">
                          <i class="flaticon2-pen text-primary"></i>
                        </span>
                      </div>
                    </a>
                    <a @click="toDelete(modalInfo)" v-if="akses">
                      <div class="btn btn-icon btn-clean btn-xl btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-xl svg-icon-danger">
                          <i class="flaticon2-trash text-danger"></i>
                        </span>
                      </div>
                    </a>
                  </b-col>
                </b-row>
              </b-modal>
            </b-col>
            <b-col md="5">
              <b-pagination
                v-model="currentPage"
                @input="paginate"
                :total-rows="totalRows"
                :per-page="10"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
            <b-col md="5">
              <h4 class="float-right">
                Showing
                <span v-if="totalPerPage">1-{{ totalPerPage }}</span>
                <span v-else>0</span>

                of {{ totalRows }}
              </h4>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import * as moment from 'moment'
import 'moment/locale/id'
import pdf from 'vue-pdf'
export default {
  data() {
    return {
      sortBy: 'judul_dokumen',
      sortDesc: false,
      sort_label: 'ASC',
      items: [],
      fields: [
        { key: 'no', label: 'No' },
        { key: 'judul_dokumen', label: 'Judul Dokumen', sortable: true },
        { key: 'nomor_dokumen', label: 'Nomor Dokumen', sortable: true },
        { key: 'jenis_dokumen', label: 'Jenis Dokumen', sortable: true },
        { key: 'versi_dokumen', label: 'Versi', sortable: true },
        {
          key: 'nama_equipment',
          label: 'Equipment',
          sortable: true,
          formatter: 'equipmentFormat',
        },
        { key: 'nama_plant', label: 'Plant/Unit', sortable: true },
        { key: 'nama_pabrik', label: 'Pabrik', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      search: {
        tanggal: '',
        nomor_dokumen: '',
        jenis_dokumen: '',
        nama_pabrik: '',
        nama_plant: '',
        equipment: '',
        versi_dokumen: '',
      },
      modalInfo: {
        id_data_desain: null,
        nama_pabrik: null,
        nama_plant: null,
        nama_equipment: null,
        nama_part: null,
        nomor_dokumen: null,
        jenis_dokumen: null,
        tanggal: null,
        versi_dokumen: null,
        file: '',
        detail: [],
      },
      infoModal: false,

      totalRows: 0,
      totalPerPage: 0,
      currentPage: 1,

      isBusy: false,
      dialog: false,
      akses: false,

      urlStorage: '',
    }
  },
  components: {
    KTCodePreview,
    pdf,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Data Desain', route: '' },
      { title: 'Data Desain' },
    ])
  },
  created() {
    var self = this
    self.urlStorage = ApiService.urlStorage()
    ApiService.get(
      '/datadesain?page=' +
        this.currentPage +
        '&tanggal=' +
        this.search.tanggal +
        '&nomor_dokumen=' +
        this.search.nomor_dokumen +
        '&jenis_dokumen=' +
        this.search.jenis_dokumen +
        '&nama_pabrik=' +
        this.search.nama_pabrik +
        '&nama_plant=' +
        this.search.nama_plant +
        '&equipment=' +
        this.search.equipment +
        '&versi_dokumen=' +
        this.search.versi_dokumen +
        '&sortBy=' +
        this.sortBy +
        '&sortDesc=' +
        this.sort_label
    )
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.akses = data.akses
          if (data.akses) {
            self.fields
          }
          self.items = data.data.data
          self.totalRows = data.data.total
          self.totalPerPage = data.data.data.length
          self.isBusy = false
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    info(item) {
      this.modalInfo.id_data_desain = item.id_data_desain
      this.modalInfo.nama_pabrik = item.nama_pabrik
      this.modalInfo.nama_plant = item.nama_plant
      this.modalInfo.nama_equipment = item.nama_equipment
      this.modalInfo.nomor_equipment = item.nomor_equipment
      this.modalInfo.nama_part = item.nama_part
      this.modalInfo.nomor_dokumen = item.nomor_dokumen
      this.modalInfo.jenis_dokumen = item.jenis_dokumen
      this.modalInfo.tanggal = item.tanggal
      this.modalInfo.versi_dokumen = item.versi_dokumen
      this.modalInfo.file = item.file
      this.modalInfo.detail = item.detail
      this.infoModal = true
      // this.$root.$emit("bv::show::modal", "info-modal", button);
    },
    resetInfoModal() {
      this.modalInfo.id_data_desain = null
      this.modalInfo.nama_pabrik = null
      this.modalInfo.nama_plant = null
      this.modalInfo.nama_equipment = null
      this.modalInfo.nomor_equipment = null
      this.modalInfo.nama_part = null
      this.modalInfo.nomor_dokumen = null
      this.modalInfo.jenis_dokumen = null
      this.modalInfo.tanggal = null
      this.modalInfo.versi_dokumen = null
      this.modalInfo.file = ''
      this.modalInfo.detail = null
    },
    moment: function(date) {
      moment.locale('id')
      return moment(date, 'YYYY-MM-DD').format('dddd, LL')
    },
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.loadData()
    },
    paginate() {
      this.loadData()
    },
    sortTable() {
      this.sort_label = 'ASC'
      if (this.sortDesc) {
        this.sort_label = 'DESC'
      }
      this.sortDesc = !this.sortDesc
      this.loadData()
    },
    async loadData() {
      var self = this
      self.isBusy = true

      await this.timeout(100)
      ApiService.get(
        '/datadesain?page=' +
          this.currentPage +
          '&tanggal=' +
          this.search.tanggal +
          '&nomor_dokumen=' +
          this.search.nomor_dokumen +
          '&jenis_dokumen=' +
          this.search.jenis_dokumen +
          '&nama_pabrik=' +
          this.search.nama_pabrik +
          '&nama_plant=' +
          this.search.nama_plant +
          '&equipment=' +
          this.search.equipment +
          '&versi_dokumen=' +
          this.search.versi_dokumen +
          '&sortBy=' +
          this.sortBy +
          '&sortDesc=' +
          this.sort_label
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.akses = data.akses
            self.items = data.data.data
            self.totalPerPage = data.data.data.length
            self.isBusy = false
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    toDelete(row) {
      var id = row.id_data_desain
      let self = this
      this.$confirm({
        auth: false,
        message: 'Anda yakin akan menghapus data tersebut?',
        button: {
          yes: 'Ya',
          no: 'Batal',
        },
        callback: confirm => {
          if (confirm) {
            ApiService.get('/datadesain/delete/' + id).then(({ data }) => {
              if (data.status == 'ok') {
                var interval1 = null
                var i = 0
                interval1 = setInterval(function() {
                  if (i == 0) {
                    self.totalRows--
                    self.makeToast('warning', 'Data berhasil dihapus')
                    self.infoModal = false
                    self.loadData()
                  } else {
                    clearInterval(interval1)
                  }
                  i++
                }, 1000)
              }
            })
          }
        },
      })
    },
    equipmentFormat(value) {
      return `${value.nama_equipment} (${value.nomor_equipment})`
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
}
</script>
